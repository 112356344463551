$(function () {

    $(".select2").select2({
    });

    $(".select2-ajax").select2({
        templateResult: (data) => {
            return data.html;
        },
        escapeMarkup: function (m) {
            return m;
        }
    });



    $('.copy-to-clipboard').click(function () {
        $text = $(this).data('text');
        copyTextToClipboard($text);
        alert('Le lien a été copié dans le presse-papier');
    });

    $('.toggle').on('click', function () {
        $('.menu').toggleClass('expanded');
        $('span').toggleClass('hidden');
        $('.container , .toggle').toggleClass('close');
    });

    // Navbar toggle
    $(".menu-user-profile .navbar-toggle").click(function () {
        $(".menu-user-profile .navbar-collapse").toggleClass("collapse").slideToggle("fast");
    });


    $('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip"]').tooltip();


    $('#dataTable, .dataTable').DataTable({
        "dom":
            "<'row'" +
            "<'col-sm-6 d-flex align-items-center justify-conten-start'l>" +
            "<'col-sm-6 d-flex align-items-center justify-content-end'f>" +
            ">" +
            "<'my-5 border rounded'" +
            "<'table-responsive'tr>" +
            ">" +
            "<'row'" +
            "<'col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'i>" +
            "<'col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'p>" +
            ">"

        ,
        "scrollY": "500px",
        "scrollCollapse": true,
        "paging": false,
        "order": [[0, 'desc']],
        "pageLength": 50,
        "oLanguage": {
            "sProcessing": "Traitement en cours...",
            "sSearch": "Rechercher&nbsp;: ",
            "sLengthMenu": "Afficher _MENU_ &eacute;l&eacute;ments",
            "sInfo": "Vue de  _START_ &agrave; _END_ sur _TOTAL_ ",
            "sInfoEmpty": "Vue de  0 &agrave; 0 sur 0 ",
            "sInfoFiltered": "(filtr&eacute; de _MAX_ )",
            "sInfoPostFix": "",
            "sLoadingRecords": "Chargement en cours...",
            "sZeroRecords": "Aucun &eacute;l&eacute;ment &agrave; afficher",
            "sEmptyTable": "Aucune donnée disponible dans le tableau",
            "oPaginate": {
                "sFirst": "Prem.",
                "sPrevious": "Prec.",
                "sNext": "Suiv.",
                "sLast": "Der."
            },
            "oAria": {
                "sSortAscending": ": activer pour trier la colonne par ordre croissant",
                "sSortDescending": ": activer pour trier la colonne par ordre décroissant"
            }
        },
    });


    $('body').on('change', '.chooseFile', function (event) {
        const fileInput = event.target;
        let file = fileInput.files[0];
        let $label = $('label[for="' + $(this).attr('id') + '"]');
        $label.html(file.name)

        let filename = $(".chooseFile").val();

        if (/^\s*$/.test(filename)) {
            $(".file-upload").removeClass('active');
            $(".noFile").text("Aucun fichier sélectionné...");
        } else {
            $(".file-upload").addClass('active');
            $(".noFile").text(filename.replace("C:\\fakepath\\", ""));
        }

    });

    $('#UploadedFile').on('change', function (event) {
        const file_input = event.target;
        if (file_input.files && file_input.files.length > 0) {
            let reader = new FileReader();
            reader.onload = function (e) {
                $('#avatarPreview').attr('src', e.target.result);
            }
            reader.readAsDataURL(file_input.files[0]);
        }
    });

    $('body').on('click', '.volonte-message-delete', function (e) {
        e.preventDefault();

        if (confirm('Etes vous sur de vouloir supprimer ce message ?')) {
            $(this).parents('.volonte-message').remove();
        }
    });

    $('body').on('click', '.contract-delete', function (e) {
        e.preventDefault();

        if (confirm('Etes vous sur de vouloir supprimer ce contrat ?')) {
            $(this).parents('.user-contract').remove();
        }
    });


    $('body').on('click', '.result_row', function () {
        $('#company_form_name').val($(this).data('name'));
        $('#company_form_siren').val($(this).data('siren'));
        $('#company_form_siret').val($(this).data('siret'));
        $('#company_form_address').val($(this).data('address'));
        $('#company_form_zipcode').val($(this).data('zipcode'));
        $('#company_form_city').val($(this).data('city'));
        $('#company_form_nature').val($(this).data('nature'));
    });

    $('#submit_search_company').click(function () {
        let url = $('#search_company').data('url');
        let query = $('#search_company').val();
        $.ajax({
            url: url,
            data: {
                'query': query
            },
            type: 'POST',
            dataType: 'html',
            success: function (data) {
                $('#result_search').html(data);
            },
            error: function (request, error) {
                $('#result_search').html('Aucun résulat');
            }
        });
    });

    $(document).on('submit', 'form[name="contact"]', (event) => {
        const form = $('form[name="contact"]');

        event.preventDefault();

        $.ajax({
            type: "POST",
            url: form.attr('action'),
            data: form.serialize(),
            dataType: "json"
        }).done(function (data) {
            if (data.success) {
                $('form[name="contact"] .modal-footer')
                    .addClass('bg-success text-white')
                    .text('Le contact à bien été sauvegardé.');
            }
        });
    });

    $(document).on('click', '#goToPayment', function () {
        const url = new URL($(this).data('url'));

        if (!url.searchParams.has('quantity')) {
            url.searchParams.append('quantity', $('[name="qty"]').val());
        }

        if (!url.searchParams.has('productId')) {
            url.searchParams.append('productId', $('.select_service input[name=service]:checked').val());
        }

        window.location.href = url.href;
    });
})
;


// $('.count').prop('disabled', true);

// $(document).on('click', '.plus', function () {
//     $('.count').val(parseInt($('.count').val()) + 1);
//     calcul_price($('.select_service input[name=service]:checked').val(), $('.count').val());
// });

// $(document).on('click', '.minus', function () {
//     $('.count').val(parseInt($('.count').val()) - 1);
//     if ($('.count').val() == 0) {
//         $('.count').val(1);
//     }
//     calcul_price($('.select_service input[name=service]:checked').val(), $('.count').val());
// });


timeOut = 0;

$('.plus').on('mousedown touchstart', function (e) {
    $('.count').val(parseInt($('.count').val()) + 1);
    calcul_price($('.select_service input[name=service]:checked').val(), $('.count').val());
    timeOut = setInterval(function () {
        $('.count').val(parseInt($('.count').val()) + 1);
        calcul_price($('.select_service input[name=service]:checked').val(), $('.count').val());
    }, 200);
}).bind('mouseup mouseleave touchend', function () {
    clearInterval(timeOut);
});

$('.minus').on('mousedown touchstart', function (e) {
    $('.count').val(parseInt($('.count').val()) - 1);
    if ($('.count').val() == 0) {
        $('.count').val(1);
    }
    calcul_price($('.select_service input[name=service]:checked').val(), $('.count').val());
    timeOut = setInterval(function () {
        $('.count').val(parseInt($('.count').val()) - 1);
        if ($('.count').val() == 0) {
            $('.count').val(1);
        }
        calcul_price($('.select_service input[name=service]:checked').val(), $('.count').val());
    }, 200);
}).bind('mouseup mouseleave touchend', function () {
    clearInterval(timeOut);
});

//
// $(document).on(' mousedown touchstart', '.minus', function() {
//     timeoutId = setTimeout(function(){
//         $('.count').val(parseInt($('.count').val()) - 1);
//         if ($('.count').val() == 0) {
//             $('.count').val(1);
//         }
//         calcul_price($('.select_service input[name=service]:checked').val(), $('.count').val());
//
//     }, 100);
// }).on('mouseup mouseleave touchend', function() {
//     clearTimeout(timeoutId);
// });


$("#button_img").bind('mousedown touchstart', function (event) {
    //determine where to look for pageX by the event type
    var pageX = (event.type.toLowerCase() === 'mousedown')
        ? event.pageX
        : event.originalEvent.touches[0].pageX;
});

// $('.select_service input[name=service]').on('click mousedown touchstart', function() {
//     timeoutId = setTimeout(function(){
//         $('[name="qty"]').val();
//         calcul_price($(this).val(), $('[name="qty"]').val())
//
//     }, 1000);
// }).on('mouseup mouseleave touchend', function() {
//     clearTimeout(timeoutId);
// });


$(document).on('click', '.select_service input[name=service]', function () {

    $('[name="qty"]').val();
    calcul_price($(this).val(), $('[name="qty"]').val());
});


$('body').on('click', '[data-bs-toggle="modal"]', function () {
    $($(this).data("bs-target") + ' .modal-content').load($(this).data("remote"));
});


function calcul_price(service, periode) {

    services = {1: 5, 2: 10, 3: 12};
    $periode = periode;
    $price = services[service] * $periode;
    console.log(service + ' :: ' + periode);
    $('#price_html').text($price);
    $('#periode_html').text(periode);
    $('#price_html_corpo').text($periode);
}

calcul_price(3, 1);


MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
var observer = new MutationObserver(function (mutations, observer) {
});

// define what element should be observed by the observer
// and what types of mutations trigger the callback
observer.observe(document, {
    subtree: true,
    attributes: true
});


function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
    } catch (err) {
    }

    document.body.removeChild(textArea);
}

function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function () {
    }, function (err) {
    });
}
